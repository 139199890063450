import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import Modal from 'react-modal';
import { Link } from "react-router-dom";

import SpecificCar from '../media/SpecificCar.png'
import AWDIcon from '../media/AWDIcon.png'
import Transmission from '../media/Transmission.png'
import Engine from '../media/Engine.png'
import Gasoline from '../media/Gasoline.png'
import MPG from '../media/MPG.png'
import Seats from '../media/Seat.png'

import leftArrow from '../media/left-arrow-2.png'
import rightArrow from '../media/right-arrow-2.png'

const Main = (props) => {

    const icons = [AWDIcon, Engine, Gasoline, MPG]

    const highlightedFeatures = ['drivetrain','engine','fuel','mpg']
    const overrideTitles = ['', '', '', '']

    const [selectedPhoto, setSelectedPhoto] = useState(0)

    const handleImageChange = (change) => {
        console.log('length: ' + props.car.photos.length)
        console.log('newVal: ' + (selectedPhoto + change))
        if (selectedPhoto + change < 0)
        {
            setSelectedPhoto(0)
        }
        else
        {
            if ((selectedPhoto + change) >= props.car.photos.length)
            {
                setSelectedPhoto(props.car.photos.length-1)
            }
            else
            {
                setSelectedPhoto((selectedPhoto) => selectedPhoto + change)
            }
        }
    }

    const [noticePopup, setNoticePopup] = useState(0)

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const deleteCar = () => {
        console.log('deleting!')
        window.apiRequest('/car/' + props.car.id, { method: 'DELETE', headers: { "Accept": "application/json", "Authorization": "Bearer " + localStorage.getItem('car_access_token'), "Content-Type": "application/json" }}, (result, status) => {
          if (status === 200)
          {
            setIsDeleteModalOpen(false)
          } else {console.log(result)}
        });
    };

    const editCar = (newValues) => {
        console.log(newValues)
        const carData = {...props.car, ...newValues };
        if (carData.photos[0].length < 300)
        {
            delete carData.photos;
        }
        window.apiRequest('/car/' + props.car.id, { method: 'PATCH', headers: { "Accept": "application/json", "Authorization": "Bearer " + localStorage.getItem('car_access_token'), "Content-Type": "application/json" }, body: JSON.stringify(carData) }, (result, status) => {
          if (status === 200)
          {
            setIsEditModalOpen(false)
          } else {console.log(result)}
        });
    }

    const [fields, setFields] = useState([{ key: '', value: '' }]);
    const [photo, setPhoto] = useState('');

    const handleFieldChange = (index, field, value) => {
        const updatedFields = [...fields];
        updatedFields[index][field] = value;
        setFields(updatedFields);
    };

    const addRow = () => {
        setFields([...fields, { key: '', value: '' }]);
    };

    const handleRemoveEmptyPhoto = () => {
        if (!photo) {
            setPhoto('');
        }
    };

    const getDataOutput = () => {
        console.log(photo)
        const data = fields.reduce((acc, { key, value }) => {
            if (key && value) acc[key] = value;
            return acc;
        }, {});

        if (photo) {
            data.photos = [photo];
        }

        return data;
    };

    const handlePhotoChange = (e) => {
        const file = e.target.files[0]; // Get the selected file
        if (file) {
            const reader = new FileReader(file); // Initialize FileReader
            reader.onloadend = () => {
                let base64Array = [];
                base64Array.push(reader.result.split(',')[1]);
                setPhoto(reader.result.split(',')[1]); // Store the Base64 value
            };
            reader.readAsDataURL(file); // Start reading the file
        }
    };
    

    return (
        <div style={{display: 'flex', flexDirection: window.innerWidth < 1360 ? 'column':'row', height: 'auto', fontFamily: 'inter', color: window.colors.primary, ...props.style, width: window.innerWidth < 1500 && '100%'}}>
            <div className='noSelect' style={{display: 'flex', flexDirection: 'column'}}>
                <img src={'https://cdn.autovitus.com/cars/' + props?.car?.photos?.[selectedPhoto]} style={{width: window.innerWidth < 1360 ? '90%':'710px', borderRadius: '20px', margin: window.innerWidth < 1360 && 'auto', height: '355px', objectFit: 'cover', border: '1px solid ' + window.colors.border}}></img>
            </div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', flexDirection: window.innerWidth < 1360 ? 'column':'row'}}>
                    <div style={{display: 'flex', flexDirection: 'column', marginLeft: '50px', textAlign: window.innerWidth < 1360 && 'center', marginTop: window.innerWidth < 1360 && '30px', marginLeft: window.innerWidth < 1360 ? 'auto':'50px', marginRight: window.innerWidth < 1360 && 'auto'}}>
                        <span style={{fontWeight: '700', fontSize: '24px'}}>{props?.car?.year} {props?.car?.brand} {props?.car?.model}</span>
                        <span style={{fontWeight: '500'}}>{props?.car?.trim}</span>
                        <span style={{fontSize: '20px', fontWeight: '700', textDecoration: 'line-through', marginTop: '30px', color: window.colors.muted}}>${window.addCommas(props?.car?.msrp)}</span>
                        <span style={{fontSize: '32px', fontWeight: '700', marginTop: '5px'}}>${window.addCommas(props?.car?.discounted_msrp)}</span>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div className='center' style={{backgroundColor: window.colors.primary, color: window.colors.white, padding: '3px 8px 3px 8px', borderRadius: '100px', textAlign: 'center', marginTop: '10px', width: '100px', marginLeft: window.innerWidth < 1360 && 'auto', marginRight: window.innerWidth < 1360 && 'auto'}}>
                                <span>{(((props?.car?.msrp - props?.car?.discounted_msrp) / props?.car?.msrp)*100).toFixed(0)}% Off</span>
                            </div>
                            {props?.car?.demo && <div style={{backgroundColor: window.colors.primary, color: window.colors.white, padding: '3px 8px 3px 8px', borderRadius: '100px', textAlign: 'center', marginTop: '10px', width: 'auto', marginLeft: window.innerWidth < 1360 && 'auto', marginRight: window.innerWidth < 1360 && 'auto', marginLeft: '10px', display: 'flex', flexDirection: 'row', padding: '5px'}}>
                                <span style={{marginLeft: '10px'}}>Demo</span>
                                <div className="center" onMouseEnter={() => setNoticePopup(1)} onMouseLeave={() => setNoticePopup(-1)} style={{ fontWeight: '500', fontSize: '16px', backgroundColor: window.colors.white, borderRadius: '100px', color: window.colors.white, width: '20px', height: '20px', cursor: 'pointer', position: 'relative', marginLeft: '10px'}}>
                                    <span style={{color: window.colors.primary}}>?</span>
                                    <Tooltip style={{left: window.innerWidth < 900 ? '-300px':'-200px'}} text={'This car was driven by customers as a loaner or on test drives.'} isVisible={noticePopup === 1}/>
                                </div>
                            </div>}
                            <div style={{backgroundColor: window.colors.primary, color: window.colors.white, padding: '3px 8px 3px 8px', borderRadius: '100px', textAlign: 'center', marginTop: '10px', width: 'auto', marginLeft: window.innerWidth < 1360 && 'auto', marginRight: window.innerWidth < 1360 && 'auto', marginLeft: '10px', display: 'flex', flexDirection: 'row', padding: '5px'}}>
                                <span style={{marginLeft: '10px'}}>{props?.car?.region}</span>
                                <div className="center" onMouseEnter={() => setNoticePopup(2)} onMouseLeave={() => setNoticePopup(-1)} style={{ fontWeight: '500', fontSize: '16px', backgroundColor: window.colors.white, borderRadius: '100px', color: window.colors.white, width: '20px', height: '20px', cursor: 'pointer', position: 'relative', marginLeft: '10px'}}>
                                    <span style={{color: window.colors.primary}}>?</span>
                                    <Tooltip style={{left: window.innerWidth < 900 ? '-300px':'-200px'}} text={'If you are outside of this region, the shipping cost might be too high.'} isVisible={noticePopup === 2}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {localStorage.getItem('car_access_token') !== null && (
                        <>
                            <motion.button 
                                onClick={() => setIsDeleteModalOpen(true)}
                                whileHover={window.bAnim}
                                style={{ backgroundColor: 'red', color: window.colors.white, padding: '15px 25px', border: 'none', borderRadius: '5px', cursor: 'pointer', fontWeight: '500', fontFamily: 'inter', marginRight: '10px', height: '50px' }}
                            >
                                Delete Car
                            </motion.button>

                            <motion.button 
                                onClick={() => setIsEditModalOpen(true)}
                                whileHover={window.bAnim}
                                style={{ backgroundColor: 'yellow', color: window.colors.black, padding: '15px 25px', border: 'none', borderRadius: '5px', cursor: 'pointer', fontWeight: '500', fontFamily: 'inter', height: '50px' }}
                            >
                                Edit Car
                            </motion.button>
                        </>
                    )}
                    <motion.a href="#vehiclePurchase" whileHover={window.bAnim} style={{backgroundColor: window.colors.primary, color: window.colors.white, padding: '15px 25px 15px 25px', border: 'none', borderRadius: '5px', cursor: 'pointer', fontWeight: '500', fontFamily: 'inter', textDecoration: 'none', textAlign: 'center', marginLeft: 'auto', height: '20px', marginRight: window.innerWidth < 1360 && 'auto', marginTop: window.innerWidth < 1360 && '20px'}}>Secure This Deal</motion.a>
                </div>
                <div style={{display: 'grid', gridTemplateColumns: window.innerWidth < 850 ? (window.innerWidth < 500 ? 'repeat(1, 1fr)':'repeat(2, 1fr)'):'repeat(4, 1fr)', gap: '13px', marginLeft: 'auto', marginRight: window.innerWidth < 1360 && 'auto', marginTop: 'auto', paddingLeft: window.innerWidth > 1360 && '50px', paddingRight: '0px', paddingBottom: '0px', paddingTop: window.innerWidth < 1360 && '20px'}}>
                    {icons.map((item, index) => {
                        return (
                            <motion.div className='center' whileHover={{scale: 1.05}} style={{display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', borderRadius: '5px', padding: '10px', backgroundColor: '#EDEDEF'}}>
                                <img src={icons[index]} style={{marginTop: '10px'}}></img>
                                <span style={{marginTop: '15px'}}>{overrideTitles[index] !== '' ? overrideTitles[index]:(index === 3 ? props?.car?.city_mpg + ' City  / ' + props?.car?.highway_mpg + ' Highway':(index === 7 ? props?.car?.[highlightedFeatures[index]] + ' Seats':props?.car?.[highlightedFeatures[index]]))}</span>
                            </motion.div>
                        )
                    })}
                </div>
            </div>

            {/* Delete Modal */}
            <Modal
                isOpen={isDeleteModalOpen}
                onRequestClose={() => setIsDeleteModalOpen(false)}
                style={{
                    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        padding: '20px',
                        borderRadius: '10px',
                    },
                }}
            >
                <h2>Confirm Delete</h2>
                <p>Are you sure you want to delete this car?</p>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <button 
                        onClick={() => setIsDeleteModalOpen(false)}
                        style={{ backgroundColor: 'gray', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
                    >
                        Cancel
                    </button>
                    <button 
                        onClick={deleteCar}
                        style={{ backgroundColor: 'red', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
                    >
                        Confirm
                    </button>
                </div>
            </Modal>

            {/* Edit Modal */}
            <Modal
                isOpen={isEditModalOpen}
                onRequestClose={() => setIsEditModalOpen(false)}
                style={{
                    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        padding: '20px',
                        borderRadius: '10px',
                        width: '500px',
                    },
                }}
            >
                <h2>Edit Car</h2>

                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                    <label style={{ fontWeight: 'bold' }}>Photos:</label>
                    <input
                        type="file"
                        onChange={handlePhotoChange}
                        onBlur={handleRemoveEmptyPhoto}
                        placeholder="Enter photo URL"
                        style={{ width: '300px', padding: '5px' }}
                    />
                </div>

                {fields.map((field, index) => (
                    <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                        <input
                            type="text"
                            value={field.key}
                            onChange={(e) => handleFieldChange(index, 'key', e.target.value)}
                            placeholder="Key"
                            style={{ width: '45%', padding: '5px' }}
                        />
                        <input
                            type="text"
                            value={field.value}
                            onChange={(e) => handleFieldChange(index, 'value', e.target.value)}
                            placeholder="Value"
                            style={{ width: '45%', padding: '5px' }}
                        />
                    </div>
                ))}

                <button
                    onClick={addRow}
                    style={{ marginTop: '10px', padding: '10px', backgroundColor: '#007BFF', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
                >
                    Add Row
                </button>

                <button
                    onClick={() => editCar(getDataOutput())}
                    style={{ marginTop: '20px', padding: '10px', backgroundColor: '#28a745', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
                >
                    Save
                </button>

                <button
                    onClick={() => setIsEditModalOpen(false)}
                    style={{ marginTop: '10px', padding: '10px', backgroundColor: '#dc3545', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
                >
                    Close
                </button>
            </Modal>
            
        </div>
    );
}

const Tooltip = ({ text, isVisible, style }) => (
    <AnimatePresence>
        {isVisible && (
            <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
                style={{
                    position: 'absolute',
                    backgroundColor: 'white',
                    color: 'black',
                    padding: '10px',
                    borderRadius: '5px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    zIndex: 150,
                    width: '300px',
                    left: '-150px',
                    fontWeight: '400',
                    ...style,
                }}
            >
                {text}
            </motion.div>
        )}
    </AnimatePresence>
);

export default Main;
