import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";

import FeatureIcon from '../media/FeatureIcon.png'

const Main = (props) => {

    console.log(props)

    const fields = [
        {
            title: 'Name',
            key: 'name'
        },
        {
            title: 'Email',
            key: 'email'
        },
        {
            title: 'Zip Code',
            key: 'zip_code'
        },
        {
            title: 'Phone Number',
            key: 'phone_number'
        },
    ]

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone_number:'',
        zip_code: '',
    })

    const handleDataChange = (key, value) => {
        setFormData(() => ({
            ...formData,
            [key]: value,
        }))
    }

    const [response, setResponse] = useState('')
    const [showResponse, setShowResponse] = useState(false)

    const submit = (formData) => {
        console.log(formData)
        var myHeaders = new Headers()
        myHeaders.append("Accept", "application/json")
        myHeaders.append("Content-Type", "application/json")

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(formData)
        }

        window.apiRequest('/contact/purchase_car/' + props?.car?.id, requestOptions, (result, status) => {   
            console.log(result)
            if (status === 200) {
                setResponse('Your message has been delivered. We will be in contact shortly.')
                setFormData({
                    name: '',
                    email: '',
                    phone_number:'',
                    zip_code: '',
                })
            } else {
                setResponse('An error has occurred! Verify you have filled out all the required fields.')
            }
            setShowResponse(true) // Show response
            setTimeout(() => setShowResponse(false), 3000) // Hide after 3 seconds
        })
    }

    const handleKeyPress = (e, formData) => {
        if (e.key === 'Enter') {
            submit(formData)
        }
    }

    return (
        <div id='vehiclePurchase' style={{display: 'flex', flexDirection: 'column', height: 'auto', fontFamily: 'inter', color: window.colors.primary, ...props.style, backgroundColor: window.colors.primary, maxWidth: '1000px', width: '60%', padding: '50px 80px 50px 80px', borderRadius: '20px', textAlign: 'center'}}>
            <span style={{color: window.colors.white, fontSize: '48px', fontWeight: '700'}}>Secure this deal on this {props.car.brand} {props.car.model}</span>
            <span style={{color: window.colors.muted, fontSize: '20px', fontWeight: '500', marginTop: '30px'}}>Fill out this form to secure this deal and a representative from AutoVitus will contact you about the next steps.</span>
            <div style={{display: 'grid', gridTemplateColumns: window.innerWidth < 1200 ? 'repeat(1, 1fr)':'repeat(2, 1fr)', gap: '50px', marginTop: '50px', textAlign: 'left'}}>
                {fields.map((item, index) => {
                    return (
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <span style={{fontWeight: '700', color: window.colors.white}}>{item.title}</span>
                            <input onChange={(e) => handleDataChange(item.key, e.target.value)} value={formData[item.key]} style={{marginTop: '10px', height: '40px', paddingLeft: '15px', fontSize: '16px', fontFamily: 'inter', backgroundColor: window.colors.white, borderRadius: '5px', border: '0px', color: window.colors.primary}}></input>
                        </div>
                    )
                })}

            </div>
            <AnimatePresence>
                    {showResponse && (
                        <motion.span
                            initial={{ opacity: 0, y: 0 }}
                            animate={{ opacity: 1, y: 20 }}
                            exit={{ opacity: 0, y: 0 }}
                            transition={{ duration: 0.5 }}
                            style={{ marginTop: '0px', fontWeight: '500', fontSize: '16px', color: response.includes('error') ? 'red' : 'green'}}
                        >
                            {response}
                        </motion.span>
                    )}
                </AnimatePresence>
                <motion.button onClick={() => submit(formData)} whileHover={{width: '350px'}} style={{width: window.innerWidth < 450 ? '90%':'300px', margin: 'auto', marginTop: '50px', backgroundColor: window.colors.white, fontFamily: 'inter', border: 'none', fontWeight: '600', fontSize: '16px', padding: '10px 5px 10px 5px', borderRadius: '5px', cursor: 'pointer'}}>Secure This Deal</motion.button>
        </div>
    );
}

export default Main;
