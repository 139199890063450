import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";

import hero from '../media/hero.png'
import heroMap from '../media/heroMap.png'
import suv from '../media/SUV.png'
import search from '../media/search.png'
import coupe from '../media/coupe.png'
import sedan from '../media/sedan.png'
import truck from '../media/truck.png'

import { Link, useNavigate } from 'react-router-dom'

const Main = (props) => {

    const bodyStyles = ['SUV','Sedan','Truck','Coupe']
    const bodyImages = [suv, sedan, truck, coupe]

    const [searchTerm, setSearchTerm] = useState('')

    const navigate = useNavigate();

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            navigate(`/shop?searchTerm=${searchTerm}`);
        }
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column', fontFamily: 'inter', color: window.colors.primary, ...props.style, textAlign: window.innerWidth < 1550 && 'center'}}>
            <h1 style={{fontSize: window.innerWidth < 800 ? (window.innerWidth < 500 ?  '32px':'48px'):'64px', maxWidth: '930px', lineHeight: '110%', letterSpacing: '-5%', margin: window.innerWidth < 1550 && 'auto', width: '100%', maxWidth: '800px'}}>Browse Pre-Negotiated Lease Deals</h1>
            <div style={{border: '0px solid ' + window.colors.border, display: 'flex', flexDirection: 'row', maxWidth: '745px', width: '90%', alignItems: 'center', height: '46px', borderRadius: '8px', marginLeft: window.innerWidth < 1550 && 'auto', marginRight: window.innerWidth < 1550 && 'auto', marginTop: window.innerWidth < 1550 && '40px', zIndex: 100, boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.1)'}}>
                <img src={search} style={{marginLeft: '15px'}}></img>
                <input placeholder='Search Make, Model, or Keyword' onKeyUp={handleKeyPress} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} style={{border: 'none', fontSize: '14px', marginLeft: '20px', fontWeight: '500', fontFamily: 'inter', outline: 'none', maxWidth: window.innerWidth < 345 ? '60%':'90%', flexGrow: 1}}></input>
                <Link to={'/shop?searchTerm=' + searchTerm}><motion.button whileHover={window.bAnim} style={{backgroundColor: window.colors.primary, padding: '10px', marginRight: '5px', borderRadius: '5px', cursor: 'pointer', border: 'none', zIndex: 1, flexGrow: 1}}>
                    <span style={{color: window.colors.white}}>GO</span>
                </motion.button></Link>
            </div>
            <div style={{display: 'flex', flexDirection: window.innerWidth < 1550 ? 'column':'row', marginTop: '-100px'}}>
                <p3 style={{fontSize: window.innerWidth < 750 ? '14px':'20px', fontWeight: '400', color: window.colors.muted, width: '90%', marginLeft: window.innerWidth < 1550 && 'auto', marginRight: window.innerWidth < 1550 && 'auto', marginTop: '130px', letterSpacing: '0.2px', lineHeight: '28px'}}>Transparent, upfront pricing with no hidden fees or dealership hassles. The price you see is the price you pay, <p3 style={{color: 'black'}}>taxes & fees included.</p3></p3>
                <img src={hero} style={{maxWidth: '853px', width: '90%', margin: window.innerWidth < 1550 && 'auto'}}></img>
            </div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                {window.innerWidth > 1550 && <img src={heroMap} style={{marginTop: '-150px'}}></img>}
                <div style={{display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: window.innerWidth < 1550 && 'auto', flexGrow: 1, textAlign: window.innerWidth < 1550 ? 'center':'right'}}>
                    <span style={{fontWeight: '700', fontSize: '20px'}}>Search by Body Style</span>
                    <div style={{display: 'flex', flexDirection: 'row-reverse', marginTop: '20px', justifyContent: window.innerWidth < 1550 && 'center', flexWrap: 'wrap'}}>
                        {bodyStyles.map((item, index) => {
                            return (
                                <Link to={'/shop?filter=' + bodyStyles[index] + '&field=bodyType'} style={{textDecoration: 'none', color: window.colors.primary}}>
                                    <motion.div whileHover={{boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.13)'}} style={{transformOrigin: 'center center', willChange: 'transform', flexGrow: 1, maxWidth: '175px', height: '130px', alignContent: 'center', textAlign: 'center', border: 'px solid ' + window.colors.border, marginLeft: window.innerWidth < 1550 ? '20px':'40px', marginRight: window.innerWidth < 1550 && '20px', borderRadius: '5px', display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', marginBottom: '10px', boxShadow: '0px 0px 10px 1px rgba(0,0,0,0.05)'}}>
                                        <div className='center' style={{height: '90px'}}>
                                            <img src={bodyImages[index]} style={{width: '80%', maxWidth: '114px'}}></img>
                                        </div>
                                        <div className='center' style={{width: '100%', backgroundColor: window.colors.white, color: window.colors.primary, flexGrow: 1, borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', borderTop: '1px solid ' + window.colors.border}}> 
                                            <span style={{width: '100%', textAlign: 'center', fontWeight: '400', fontSize: '18px'}}>{item}</span>
                                        </div>      
                                    </motion.div>
                                </Link>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;
